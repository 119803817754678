import React from 'react';
import _ from 'lodash';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styled from '@emotion/styled';
import CardHeader from '@material-ui/core/CardHeader';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ListSubheader from '@material-ui/core/ListSubheader';

import { graphql } from 'gatsby';
import AvatarTextListItem from '../AvatarTextListItem';
import LocalizedLink from '../../components/localizedLink';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 80%;
  /*padding: 160px 1.0875rem 1.45rem;*/
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    /* border: `2px solid ${theme.palette.divider}`, */
    '& hr': {
      margin: theme.spacing(0, 0.5),
      height: '1px',
      backgroundColor: theme.palette.primary,
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  control: {
    padding: theme.spacing(2),
  },
  inline: {
    display: 'inline',
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    padding: theme.spacing(2),
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  dividerColor: {
    backgroundColor: 'black',
  },
}));

const TagRoute = ({
  data: {
    allMdxResourcePost: { edges: postNodes },
  },
  pageContext,
}) => {
  const classes = useStyles();

  /* const posts = data.allMdxResourcePost.edges
  const postLinks = posts.map(post => (
    <li key={post.node.slug}>
      <Link to={post.node.slug}>
        <h2 className="is-size-2">{post.node.title}</h2>
      </Link>
    </li>
  )) */
  const { tag } = pageContext;
  const { title } = pageContext;

  return (
    <Wrapper>
      <div>{`posts tagged with "${tag}"`}</div>
      <List>
        {postNodes.map(({ node: post }, idx) => (
          <>
            <AvatarTextListItem post={post} />
            <Divider
              variant="middle"
              component="li"
              className={classes.dividerColor}
            />
          </>
        ))}
      </List>
    </Wrapper>
  );
};

export default TagRoute;

export const tagPageQuery = graphql`
  query PostsByTag($tag: String!, $locale: String!) {
    allMdxResourcePost(
      filter: { tags: { in: [$tag] }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          locale
          id
          title
          slug
          authorId
          excerpt
          ... on MdxResourcePost {
            id
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
